import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

export type HeaderMenuItemProps = {
  path: string;
  label: string;
  isMobileMenuOpen?: boolean;
};

const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({
  path,
  label,
  isMobileMenuOpen = false,
}) => {
  const { pathname } = useLocation();
  const isCurrentRoute = pathname === path;

  return (
    <li key={path} className={cn({ "mb-12": isMobileMenuOpen }, { "mr-6": !isMobileMenuOpen })}>
      <Link
        to={path}
        className={cn(
          "text-xs pb-1 font-medium transition border-b text-zinc-700",
          { "text-lg": isMobileMenuOpen },
          { "border-zinc-300": isCurrentRoute },
          { "border-transparent": !isCurrentRoute }
        )}
      >
        {label}
      </Link>
    </li>
  );
};

export { HeaderMenuItem };
