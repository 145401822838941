import cn from "classnames";
import { Config } from "@/config";
import LogoImg from "@/assets/images/logo.png";

type AppLogoProps = {
  variation?: "white";
};

const AppLogo: React.FC<AppLogoProps> = ({ variation }) => (
  <div className="w-fit">
    <a href="/" className="flex items-center">
      <img src={LogoImg} alt="Logo Incorporadora Margarezi" className="h-16 mr-2" />
      <span
        className={cn(
          "self-center text-lg md:text-base lg:text-lg font-extralight",
          { "text-zinc-700": !!variation },
          { "text-white": variation === "white" }
        )}
      >
        {Config.appName}
      </span>
    </a>
  </div>
);

export { AppLogo };
