import cn from "classnames";
import { yellow } from "tailwindcss/colors";
import { Icon } from "@/components/Icon";

type HeaderTopNavItemProps = {
  icon: string;
  text: string;
  className?: string;
};

const HeaderTopNavItem: React.FC<HeaderTopNavItemProps> = ({ icon, text, className }) => (
  <div className={cn(className, "flex")}>
    <Icon name={icon} size={16} color={yellow[500]} />
    <span className="text-zinc-300 text-xs ml-1">{text}</span>
  </div>
);

export { HeaderTopNavItem };
