import { HeaderMainNav } from "./HeaderMainNav";
import { HeaderTopNav } from "./HeaderTopNav";

const Header = () => (
  <header className="fixed z-50 w-full">
    <HeaderTopNav />
    <HeaderMainNav />
  </header>
);

export { Header };
