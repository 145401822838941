import cn from "classnames";
import { Carousel, CarouselItemProps } from "@/components/Carousel";
import { SectionTitle } from "../../SectionTitle";

type ParagraphImageSectionProps = {
  title: string;
  paragraphText: string;
  carouselItems: CarouselItemProps[];
  direction?: "ltr" | "rtl";
  isFullScreen?: boolean;
};

const ParagraphImageSection: React.FC<ParagraphImageSectionProps> = ({
  title,
  paragraphText,
  carouselItems,
  direction = "ltr",
  isFullScreen = false,
}) => (
  <section
    className={cn("flex justify-between flex-col", {
      "md:flex-row": direction === "ltr",
      "md:flex-row-reverse": direction === "rtl",
    })}
  >
    <div
      className={cn("mb-10 md:mb-0", {
        "w-full": isFullScreen,
        "md:w-1/2": !isFullScreen,
      })}
    >
      <SectionTitle title={title} />
      <div
        dangerouslySetInnerHTML={{ __html: paragraphText }}
        className="text-zinc-600 font-light mt-8"
      />
    </div>
    <div
      className={cn("md:w-1/2", {
        "md:ml-10": direction === "ltr",
        "md:mr-10": direction === "rtl",
        "w-full": isFullScreen,
      })}
    >
      {carouselItems.length > 0 && <Carousel items={carouselItems} className="rounded-xl" />}
    </div>
  </section>
);

export { ParagraphImageSection };
