import { WhatsAppButton } from "@/components/WhatsAppButton";

const HomeHero = () => (
  <section className="w-screen h-[40rem] 2xl:h-[50rem] relative">
    <div className="absolute w-full h-full bg-black opacity-70" />
    <div className="flex items-center justify-center w-full h-full bg-cover bg-no-repeat bg-center bg-[url(/src/assets/images/casa-terrea/sala-estar-1.jpeg)]">
      <div className="flex flex-col items-center justify-center h-full z-20 relative text-center px-4">
        <span className="text-white">Condomínio Residencial Villa Margarezi</span>
        <span className="text-white uppercase text-2xl sm:text-4xl md:text-4xl lg:text-6xl 2xl:text-7xl mt-4">
          Seu imóvel <span className="text-yellow-2">seguro</span> em
          <br />
          localização <span className="text-yellow-2">privilegiada</span>
        </span>
        <span className="text-zinc-200 mt-4 uppercase font-light text-xl">
          15 minutos do centro de Foz do Iguaçu
        </span>
        <WhatsAppButton className="mt-8" />
      </div>
    </div>
  </section>
);

export { HomeHero };
