import { ParagraphImageSection } from "@/components/pages/shared/ParagraphImageSection";
import AboutUsImg1 from "@/assets/images/sobre/sobre-1.jpeg";
import AboutUsImg2 from "@/assets/images/sobre/sobre-2.jpeg";
import AboutUsImg3 from "@/assets/images/sobre/sobre-3.jpeg";
import AboutUsImg4 from "@/assets/images/sobre/sobre-4.jpeg";
import AboutUsImg5 from "@/assets/images/sobre/sobre-5.jpeg";
import { Config } from "@/config";

const CompanyHistorySection = () => (
  <section className="max-w-7xl px-4 mx-auto">
    <ParagraphImageSection
      title="Sobre nós"
      paragraphText={`
      <p class="text-2xl font-extralight">
        Somos uma empresa familiar e estamos a mais de 10 anos no mercado,
        realizando sonhos de família para família.
      </p>
      <br />
      Naturais de Foz do Iguaçu, temos um carinho muito especial pela nossa Terra das Cataratas
      e nos orgulhamos de fazer parte da história dessa cidade com a construção da Villa Margarezi.
      <br /><br />
      Venha tomar um cafézinho com a gente e conhecer nosso escritório na ${Config.metaData.address}. Estamos esperando por você!`}
      carouselItems={[
        { imageUrl: AboutUsImg1 },
        { imageUrl: AboutUsImg2 },
        { imageUrl: AboutUsImg3 },
        { imageUrl: AboutUsImg4 },
        { imageUrl: AboutUsImg5 },
      ]}
    />
  </section>
);

export { CompanyHistorySection };
