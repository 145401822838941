export type MapPinProps = {
  lat: number;
  lng: number;
  title: string;
};

const MapPin: React.FC<MapPinProps> = ({ title }) => (
  <div className="w-28 bg-yellow-5 border-2 border-yellow-4 py-2 rounded-xl rounded-tl-none text-center">
    <span className="font-medium text-white text-xs">{title}</span>
  </div>
);

export { MapPin };
