import { Config } from "@/config";
import cn from "classnames";
import { Button } from "./Button";

type WhatsAppButtonProps = {
  text?: string;
  number?: string;
  className?: string;
};

const formatNumber = (number: string) => number.replace(/[^0-9]/g, "");

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ text, number, className }) => {
  const handleClick = () => {
    const phoneNumber = number || Config.metaData.whatsAppPhoneNumberFormatted;
    const url = `https://api.whatsapp.com/send?phone=55${formatNumber(phoneNumber)}`;
    window.open(url, "_blank");
  };

  return (
    <Button
      type="primary"
      iconName="BsWhatsapp"
      className={cn("bg-green-900", className)}
      onClick={handleClick}
    >
      {text ?? "FALAR COM UM CONSULTOR"}
    </Button>
  );
};

export { WhatsAppButton };
