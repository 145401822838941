import { AboutUsPage } from "@/pages/AboutUsPage";
import { HomePage } from "@/pages/HomePage";
import { useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

const AppRouter = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/sobre" element={<AboutUsPage />} />
    </Routes>
  );
};

export { AppRouter };
