import { ListImageSection } from "@/components/pages/shared/ListImageSection";
import Sobrado1Image from "@/assets/images/sobrado/sobrado-1.png";
import Aerea1Image from "@/assets/images/aerea-1.png";
import CasaTerrea1Image from "@/assets/images/casa-terrea/casa-terrea-1.jpeg";
import CasaTerrea2Image from "@/assets/images/casa-terrea/sala-estar-1.jpeg";
import CasaTerrea3Image from "@/assets/images/casa-terrea/cozinha-1.jpeg";
import CasaTerrea4Image from "@/assets/images/casa-terrea/cozinha-2.jpeg";
import CasaTerrea5Image from "@/assets/images/casa-terrea/lavanderia-1.jpeg";
import CasaTerrea6Image from "@/assets/images/casa-terrea/despensa-1.jpeg";
import CasaTerrea7Image from "@/assets/images/casa-terrea/quarto-1.jpeg";
import CasaTerrea8Image from "@/assets/images/casa-terrea/banheiro-social-1.jpeg";
import CasaTerrea9Image from "@/assets/images/casa-terrea/suite-1.jpeg";
import CasaTerrea10Image from "@/assets/images/casa-terrea/banheiro-suite-1.jpeg";
import CasaTerrea11Image from "@/assets/images/casa-terrea/gourmet-1.jpeg";

const RealEstateDetailsSection = () => (
  <section className="max-w-7xl px-4 mx-auto">
    <ListImageSection
      title="Detalhes dos imóveis"
      listItems={[
        {
          title: "Sobrado (166,31m²)",
          description: `
            <p>Não geminado, porta de entrada em alumínio e internas em madeira com pintura
            eletrostática, teto em laje com acabamento em gesso e iluminação em led, revestimento do
            piso em porcelanato, janelas em esquadria de alumínio com persiana integrada manual nos
            quartos, casa de gás, infraestrutura para tubulação de ar condicionado, espera para ligação de
            energia solar.</p>
            <br />
            <ul>
              <li>- 1 suíte com closet e sacada</li>
              <li>- 2 quartos</li>
              <li>- 1 banheiro social</li>
              <li>- Cozinha</li>
              <li>- Lavabo</li>
              <li>- Despensa</li>
              <li>- Sala de estar e jantar integradas</li>
              <li>- Lavanderia</li>
              <li>- Área de serviço</li>
              <li>- Garagem coberta para 2 veículos</li>
              <li>- Espaço com cerca de 80m² no fundo do terreno</li>
            </ul>
            <br />
            <b>Obs.: banheiros e lavabos entregues completos e decorados.</b>
          `,
          carouselItems: [{ imageUrl: Sobrado1Image }],
        },
        {
          title: "Casa térrea (158,53m²)",
          description: `
            <p>Não geminada, porta de entrada em alumínio e internas em madeira com pintura
            eletrostática, teto em laje com acabamento em gesso e iluminação em led, revestimento do
            piso em porcelanato, janelas em esquadria de alumínio com persiana integrada manual nos
            quartos, casa de gás, infraestrutura para tubulação de ar condicionado, espera para ligação de
            energia solar.</p>
            <br />
            <ul>
              <li>- 1 suíte com closet</li>
              <li>- 2 quartos</li>
              <li>- 1 banheiro social</li>
              <li>- Cozinha com ilha</li>
              <li>- Lavabo</li>
              <li>- Despensa</li>
              <li>- Sala de estar e jantar integradas</li>
              <li>- Jardim de inverno</li>
              <li>- Lavanderia</li>
              <li>- Área de serviço</li>
              <li>- Área gourmet</li>
              <li>- Depósito externo para 02 botijões de gás</li>
              <li>- Garagem coberta para 2 veículos</li>
              <li>- Espaço com cerca de 35m² no fundo do terreno</li>
            </ul>
            <br />
            <b>Obs.: banheiros e lavabos entregues completos e decorados.</b>
          `,
          carouselItems: [
            { imageUrl: CasaTerrea1Image },
            { imageUrl: CasaTerrea2Image },
            { imageUrl: CasaTerrea3Image },
            { imageUrl: CasaTerrea4Image },
            { imageUrl: CasaTerrea5Image },
            { imageUrl: CasaTerrea6Image },
            { imageUrl: CasaTerrea7Image },
            { imageUrl: CasaTerrea8Image },
            { imageUrl: CasaTerrea9Image },
            { imageUrl: CasaTerrea10Image },
            { imageUrl: CasaTerrea11Image },
          ],
        },
        {
          title: "Terreno",
          description: `
            <p>Temos apenas 2 terrenos disponíveis para venda, um com 230,94m² e outro com 263,98m². Fale com um consultor para mais informações!</p>
          `,
          carouselItems: [{ imageUrl: Aerea1Image }],
        },
      ]}
    />
  </section>
);

export { RealEstateDetailsSection };
