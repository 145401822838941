import { AppLogo } from "@/components/AppLogo";
import { useCallback, useState } from "react";
import { HeaderMenu } from "./HeaderMenu";
import { MobileMenuButton } from "./MobileMenuButton";

const HeaderMainNav = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const handleToggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  return (
    <nav className="bg-white w-full py-2 h-16 shadow">
      <div className="max-w-7xl flex justify-between items-center h-full mx-auto w-full px-4">
        <AppLogo />
        <HeaderMenu isMobileMenuOpen={isMobileMenuOpen} />
        <MobileMenuButton isMobileMenuOpen={isMobileMenuOpen} onClick={handleToggleMobileMenu} />
      </div>
    </nav>
  );
};

export { HeaderMainNav };
