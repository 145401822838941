type SectionTitleProps = {
  title: string;
};

const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => (
  <h2 className="text-5xl md:text-6xl uppercase font-bold text-zinc-800 border-l-8 border-l-yellow-4 pl-4">
    {title}
  </h2>
);

export { SectionTitle };
