import { PageLayout } from "@/components/layout/PageLayout";
import { AboutUsHero } from "@/components/pages/about-us/AboutUsHero";
import { CompanyHistorySection } from "@/components/pages/about-us/CompanyHistorySection";
import { TeamSection } from "@/components/pages/shared/TeamSection";
import { CompanyLocationSection } from "@/components/pages/about-us/CompanyLocationSection";

const AboutUsPage = () => (
  <PageLayout>
    <AboutUsHero />
    <div className="mt-12 md:mt-28">
      <CompanyHistorySection />
    </div>
    <div className="mt-12 md:mt-28">
      <TeamSection />
    </div>
    <div className="mt-12 md:mt-28">
      <CompanyLocationSection />
    </div>
  </PageLayout>
);

export { AboutUsPage };
