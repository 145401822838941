import { Footer } from "./Footer";
import { Header } from "./Header";
import { PageContent } from "./PageContent";

type BaseLayoutProps = {
  children: React.ReactNode;
};

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => (
  <div className="overflow-x-hidden">
    <Header />
    <div className="pt-18 md:pt-24">
      <PageContent>{children}</PageContent>
    </div>
    <Footer />
  </div>
);

export { BaseLayout };
