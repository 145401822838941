export type BenefitCardProps = {
  imgSource: string;
  title: string;
  text: string;
};

const BenefitCard: React.FC<BenefitCardProps> = ({ imgSource, title, text }) => (
  <div className="flex flex-col items-center justify-center w-full lg:w-1/5 h-56 rounded-xl bg-white border border-zinc-200 shadow-lg px-5 text-center">
    <img src={imgSource} alt={title} className="h-12" />
    <span className="mt-4 text-black font-semibold uppercase leading-tight h-6">{title}</span>
    <p className="mt-2 text-zinc-600 font-light text-sm leading-tight">{text}</p>
  </div>
);

export { BenefitCard };
