import { HeaderTopNavItem } from "./HeaderTopNavItem";
import { Config } from "@/config";

const HeaderTopNav = () => (
  <nav className="hidden md:block h-8 w-full bg-black">
    <div className="max-w-7xl px-4 mx-auto flex justify-between items-center h-full">
      <div className="flex gap-3 ">
        <HeaderTopNavItem icon="MdPhone" text={Config.metaData.phone} />
        <HeaderTopNavItem
          icon="MdStayPrimaryPortrait"
          text={Config.metaData.whatsAppPhoneNumberFormatted}
        />
        <HeaderTopNavItem icon="MdPlace" text={Config.metaData.address} className="ml-8" />
      </div>
      <div className="flex items-center">
        {/* <HeaderTopNavItem icon="MdOutlineWatchLater" text={Config.metaData.openHours} /> */}
        <a href={`mailto:${Config.metaData.mainEmail}`}>
          <HeaderTopNavItem
            icon="MdOutlineEmail"
            text={Config.metaData.mainEmail}
            className="ml-8"
          />
        </a>
      </div>
    </div>
  </nav>
);

export { HeaderTopNav };
