import { Icon } from "@/components/Icon";

type MobileMenuButtonProps = {
  onClick: () => void;
  isMobileMenuOpen: boolean;
};

const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({ onClick, isMobileMenuOpen }) => (
  <button type="button" className="md:hidden" onClick={onClick}>
    <span className="sr-only">Abrir menu</span>
    {isMobileMenuOpen ? <Icon name="MdClose" size={30} /> : <Icon name="MdMenu" size={30} />}
  </button>
);

export { MobileMenuButton };
