import { Info } from "@/components/Info";
import { ParagraphImageSection } from "@/components/pages/shared/ParagraphImageSection";

const CondominiumSection = () => (
  <section className="max-w-7xl px-4 mx-auto">
    <ParagraphImageSection
      isFullScreen
      title="O Condomínio"
      paragraphText={`
      <p class="text-2xl font-extralight">
        Estrutura completa e ambiente aconchegante, com a segurança e
        a privacidade que a sua família merece.
      </p>
      <br />
      Adquira seu imóvel no Jardim Lancaster V, próximo à Vila A e
      a 15 minutos do centro de Foz do Iguaçu.
      <br /><br />
      Excelente localização e com pequenos centros comerciais na região.
      Tudo o que você precisa no seu dia a dia, muito próximo a você!
      <br /><br />
      Valor de condomínio justo e a estrutura que você precisa para viver bem!`}
      carouselItems={[]}
    />
    {false && (
      <div className="flex flex-col md:flex-row items-center justify-between mt-12">
        <Info
          title="Progresso da Obra"
          value="xx"
          suffix="% concluído"
          className="w-full mb-4 md:mb-0 md:w-48"
        />
        <Info
          title="Dias até a entrega"
          value="xx"
          suffix="dias"
          className="w-full mb-4 md:mb-0 md:w-48"
        />
        <Info title="Área total" value="xx" suffix="m²" className="w-full mb-4 md:mb-0 md:w-48" />
        <Info title="Lotes" value="xx" suffix="un." className="w-full mb-4 md:mb-0 md:w-48" />
        <Info title="Imóveis" value="xx" suffix="un." className="w-full mb-4 md:mb-0 md:w-48" />
      </div>
    )}
  </section>
);

export { CondominiumSection };
