import { Config } from "@/config";
import GoogleMapReact from "google-map-react";
import { MapPin, MapPinProps } from "./MapPin";

export type MapCoordinates = {
  lat: number;
  lng: number;
};

type MapProps = {
  width: string;
  height: string;
  center: MapCoordinates;
  zoom?: number;
  pins: MapPinProps[];
  className?: string;
};

const Map: React.FC<MapProps> = ({ width, height, center, zoom, pins, className }) => {
  const defaultProps = {
    center,
    zoom,
  };

  const getGoogleMapsKey = (): string => {
    if (process.env.REACT_APP_ENV === Config.environments.production) {
      return process.env.REACT_APP_GOOGLE_MAPS_KEY ?? "";
    }

    return "";
  };

  return (
    <div style={{ width, height }} className={className}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: getGoogleMapsKey(),
          language: "pt-BR",
          region: "BR",
        }}
        options={{
          streetViewControl: true,
          mapTypeControl: true,
          styles: [
            {
              featureType: "all",
              elementType: "labels",
              stylers: [{ visibility: "on" }],
            },
          ],
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom ?? 11}
      >
        {pins.map((pin) => (
          <MapPin key={`${pin.lat}${pin.lng}`} lat={pin.lat} lng={pin.lng} title={pin.title} />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export { Map };
