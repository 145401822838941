import { Carousel, CarouselItemProps } from "@/components/Carousel";
import { Icon } from "@/components/Icon";
import cn from "classnames";
import { useCallback, useState } from "react";
import { zinc } from "tailwindcss/colors";
import { SectionTitle } from "../../SectionTitle";

type ListItem = {
  title: string;
  description: string;
  carouselItems: CarouselItemProps[];
};

type ListImageSectionProps = {
  title: string;
  listItems: ListItem[];
  direction?: "ltr" | "rtl";
};

const ListImageSection: React.FC<ListImageSectionProps> = ({
  title,
  listItems,
  direction = "ltr",
}) => {
  const [selectedItem, setSelectedItem] = useState<number>(0);

  const isSelectedItem = useCallback((index: number) => index === selectedItem, [selectedItem]);

  return (
    <section>
      <SectionTitle title={title} />
      <div
        className={cn("flex justify-between flex-col mt-8", {
          "md:flex-row": direction === "ltr",
          "md:flex-row-reverse": direction === "rtl",
        })}
      >
        <div className="md:w-1/2 mb-10 md:mb-0">
          <div className="border-x border-x-zinc-200 rounded-tl-xl rounded-tr-xl rounded-bl-xl rounded-br-xl">
            {listItems.map((listItem, index) => {
              const isCurrentSelectedItem = isSelectedItem(index);

              return (
                <div
                  key={listItem.title}
                  className={cn("border-b border-zinc-200", {
                    "rounded-bl-xl rounded-br-xl": index === listItems.length - 1,
                  })}
                >
                  <div
                    className={cn(
                      "w-full flex items-center justify-between px-6 h-16 cursor-pointer bg-zinc-100 transition",
                      {
                        "rounded-tl-xl rounded-tr-xl border-t": index === 0,
                        "rounded-bl-xl rounded-br-xl":
                          index === listItems.length - 1 && !isCurrentSelectedItem,
                        "bg-white": isCurrentSelectedItem,
                      }
                    )}
                    onClick={() => setSelectedItem(index)}
                  >
                    <span className="font-medium text-lg text-zinc-700">{listItem.title}</span>
                    <div className={cn("transition", { "rotate-180": isCurrentSelectedItem })}>
                      <Icon name="MdKeyboardArrowDown" color={zinc[700]} size={32} />
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: listItem.description }}
                    className={cn("text-zinc-600 font-light px-6 py-4 transition bg-white", {
                      "h-full max-h-72 overflow-y-scroll block": isCurrentSelectedItem,
                      "h-0 overflow-hidden hidden": !isCurrentSelectedItem,
                    })}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={cn("md:w-1/2", {
            "md:ml-10": direction === "ltr",
            "md:mr-10": direction === "rtl",
          })}
        >
          {listItems[selectedItem].carouselItems.length > 0 ? (
            listItems[selectedItem].carouselItems.length > 1 ? (
              <Carousel items={listItems[selectedItem].carouselItems} className="rounded-xl" />
            ) : (
              <img
                src={listItems[selectedItem].carouselItems[0].imageUrl}
                alt={listItems[selectedItem].carouselItems[0].alt}
                className="rounded-xl"
              />
            )
          ) : (
            <div className="flex items-center justify-center w-full h-72">
              <span className="text-red-700 font-2xl font-bold">Fotos em breve.</span>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export { ListImageSection };
