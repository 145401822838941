import { EmployeeCard, EmployeeCardProps } from "@/components/EmployeeCard";
import { SectionTitle } from "@/components/SectionTitle";
import { Config } from "@/config";

const employees: EmployeeCardProps[] = [
  {
    name: "Leandro Margarezi",
    roles: ["Sócio", "Departamento de Obras"],
    avatarUrl: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
    phone: "(45) 9 9155-4049",
  },
  {
    avatarUrl: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
    name: "Silvana Margarezi",
    roles: ["Sócia", "Administrativo", "Financeiro"],
    phone: "(45) 9 9108-2560",
  },
  {
    avatarUrl: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
    name: "Kare Cristina dos Santos Ribeiro",
    roles: ["Departamento Comercial"],
    phone: Config.metaData.whatsAppPhoneNumberFormatted,
  },
];

const TeamSection = () => (
  <section className="max-w-7xl px-4 mx-auto">
    <SectionTitle title="Nossa equipe" />
    <div className="flex justify-center items-center md:items-start flex-col md:flex-row mt-8 gap-8">
      {employees.map((employee) => (
        <EmployeeCard
          key={`employee-${employee.name}`}
          avatarUrl={employee.avatarUrl}
          name={employee.name}
          roles={employee.roles}
          phone={employee.phone}
        />
      ))}
    </div>
  </section>
);

export { TeamSection };
