import { ParagraphImageSection } from "@/components/pages/shared/ParagraphImageSection";
import CasaTerrea1Image from "@/assets/images/casa-terrea/casa-terrea-1.jpeg";
import Sobrado1Image from "@/assets/images/sobrado/sobrado-1.png";
import Aerea1Image from "@/assets/images/aerea-1.png";

const RealEstatesSection = () => (
  <section className="max-w-7xl px-4 mx-auto">
    <ParagraphImageSection
      direction="rtl"
      title="Os Imóveis"
      paragraphText={`
      <p class="text-2xl font-extralight">
        3 opções de imóveis no Condomínio para melhor atender
        às necessidades da sua família: casa térrea,
        sobrado (ambos não-geminados) e terrenos sem construção.
      </p>
      <br />
      Essa é a sua chance de morar em um Condomínio seguro e confortável,
      no tipo de imóvel que você sempre sonhou. Você pode escolher!
      <br /><br />
      Fale com um de nossos consultores para conhecer os detalhes do projeto.`}
      carouselItems={[
        { imageUrl: CasaTerrea1Image },
        { imageUrl: Sobrado1Image },
        { imageUrl: Aerea1Image },
      ]}
    />
  </section>
);

export { RealEstatesSection };
