const AboutUsHero = () => (
  <section className="bg-no-repeat bg-cover md:pb-0 h-72 md:h-80 w-screen relative bg-center bg-[url('https://images.pexels.com/photos/7641859/pexels-photo-7641859.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')]">
    <div className="bg-black absolute w-full h-full opacity-50" />
    <div className="md:max-w-7xl md:px-4 mx-auto h-full w-full flex justify-center items-center relative">
      <h1 className="text-white font-thin tracking-wide text-4xl md:text-6xl">A INCORPORADORA</h1>
    </div>
  </section>
);

export { AboutUsHero };
