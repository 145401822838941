import { AppLogo } from "@/components/AppLogo";
import { Icon } from "@/components/Icon";
import { Config } from "@/config";
import { yellow } from "tailwindcss/colors";

const aboutUsItems = [
  {
    icon: "MdPlace",
    text: Config.metaData.address,
  },
  {
    icon: "MdPhone",
    text: Config.metaData.phone,
  },
  {
    icon: "MdOutlineWatchLater",
    text: Config.metaData.openHours,
  },
  {
    icon: "MdOutlineEmail",
    text: Config.metaData.mainEmail,
  },
];

const Footer = () => (
  <footer className="bg-yellow-700">
    <div className="max-w-7xl mx-auto px-4 py-10 flex justify-between flex-col md:flex-row items-center md:items-start text-center md:text-start">
      <div className="flex-grow basis-1">
        <div className="flex justify-center md:justify-start flex-col items-center md:items-start">
          <AppLogo variation="white" />
          <p className="text-yellow-100 font-light text-xs mt-4">
            Mais do que imóveis. Lares! <br />
            Entre em contato para conhecer o projeto.
          </p>
        </div>
      </div>
      <div className="flex-grow basis-1 md:mx-10 my-8 md:my-0">
        <span className="text-white text-lg font-medium">FALE CONOSCO</span>
        <ul className="mt-4">
          {aboutUsItems.map((item) => (
            <li key={item.text} className="mb-2">
              <div className="flex items-center justify-center md:justify-start">
                <Icon name={item.icon} size={20} color={yellow[400]} />
                <div
                  className="ml-2 font-light text-sm text-yellow-100"
                  dangerouslySetInnerHTML={{ __html: item.text }}
                ></div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex-grow basis-1">
        <span className="text-white text-lg font-medium">NOSSOS CANAIS</span>
        <div className="mt-4 flex items-center justify-center md:justify-start">
          <a href={Config.metaData.facebookUrl} rel="noreferrer" target="_blank" className="mr-8">
            <Icon name="BsFacebook" color={yellow[400]} size={40} />
          </a>
          <a href={Config.metaData.instagramUrl} rel="noreferrer" target="_blank">
            <Icon name="BsInstagram" color={yellow[400]} size={40} />
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export { Footer };
