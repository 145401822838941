import { zinc } from "tailwindcss/colors";
import { Icon } from "./Icon";
import { WhatsAppButton } from "./WhatsAppButton";

export type EmployeeCardProps = {
  avatarUrl: string;
  name: string;
  roles: string[];
  phone: string;
  email?: string;
};

const EmployeeCard: React.FC<EmployeeCardProps> = ({ avatarUrl, name, roles, email, phone }) => (
  <div className="flex flex-col items-center w-80 shadow border border-zinc-200 rounded-xl px-4 py-6 ">
    {/* <div className="w-48 h-48 rounded-full overflow-hidden">
      <img src={avatarUrl} alt={name} className="w-full" />
    </div> */}
    <span className="text-xl text-center font-bold text-zinc-800 h-12">{name}</span>
    <div className="flex flex-col items-center justify-center h-16">
      {roles.map((role) => (
        <span key={`role-${role}`} className="text-sm text-yellow-5 font-light">
          {role}
        </span>
      ))}
    </div>
    <div>
      <div className="flex items-center mt-4">
        <Icon name="MdCall" color={zinc[700]} size={18} />
        <span className="font-light text-zinc-700 text-sm ml-2">{phone}</span>
      </div>
      {email && (
        <div className="flex items-center mt-2">
          <Icon name="MdMail" color={zinc[700]} size={18} />
          <a
            href={`mailto:${email}`}
            className="font-light text-zinc-700 text-sm ml-2 hover:underline"
          >
            {email}
          </a>
        </div>
      )}
    </div>
    <div className="mt-6">
      <WhatsAppButton text="Chamar no WhatsApp" number={phone} className="text-sm" />
    </div>
  </div>
);

export { EmployeeCard };
