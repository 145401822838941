import { Carousel, CarouselItemProps } from "@/components/Carousel";
import { SectionTitle } from "@/components/SectionTitle";
import Obra1Image from "@/assets/images/obra/obra-1.png";
import Obra2Image from "@/assets/images/obra/obra-2.png";
import Obra3Image from "@/assets/images/obra/obra-3.png";
import Obra4Image from "@/assets/images/obra/obra-4.png";
import Obra5Image from "@/assets/images/obra/obra-5.png";
import Obra6Image from "@/assets/images/obra/obra-6.png";
import Obra7Image from "@/assets/images/obra/obra-7.png";
import Obra8Image from "@/assets/images/obra/obra-8.png";
import Obra9Image from "@/assets/images/obra/obra-9.png";
import Obra10Image from "@/assets/images/obra/obra-10.png";
import Obra11Image from "@/assets/images/obra/obra-11.png";
import Obra12Image from "@/assets/images/obra/obra-12.png";
import Obra13Image from "@/assets/images/obra/obra-13.png";
import Obra14Image from "@/assets/images/obra/obra-14.png";
import Obra15Image from "@/assets/images/obra/obra-15.png";
import Obra16Image from "@/assets/images/obra/obra-16.png";
import Obra17Image from "@/assets/images/obra/obra-17.png";
import Obra18Image from "@/assets/images/obra/obra-18.png";
import Obra19Image from "@/assets/images/obra/obra-19.png";
import Obra20Image from "@/assets/images/obra/obra-20.jpeg";
import Obra21Image from "@/assets/images/obra/obra-21.jpeg";
import Obra22Image from "@/assets/images/obra/obra-22.jpeg";

const carouselItems: CarouselItemProps[] = [
  { imageUrl: Obra1Image },
  { imageUrl: Obra2Image },
  { imageUrl: Obra3Image },
  { imageUrl: Obra4Image },
  { imageUrl: Obra5Image },
  { imageUrl: Obra6Image },
  { imageUrl: Obra7Image },
  { imageUrl: Obra8Image },
  { imageUrl: Obra9Image },
  { imageUrl: Obra10Image },
  { imageUrl: Obra11Image },
  { imageUrl: Obra12Image },
  { imageUrl: Obra13Image },
  { imageUrl: Obra14Image },
  { imageUrl: Obra15Image },
  { imageUrl: Obra16Image },
  { imageUrl: Obra17Image },
  { imageUrl: Obra18Image },
  { imageUrl: Obra19Image },
  { imageUrl: Obra20Image },
  { imageUrl: Obra21Image },
  { imageUrl: Obra22Image },
].reverse();

const FOUR_SECONDS = 4000;

const ConstructionProgressSection = () => (
  <section className="max-w-7xl px-4 mx-auto">
    <SectionTitle title="Progresso da obra" />
    <div className="mt-10">
      <Carousel
        centerMode
        autoPlay
        infiniteLoop
        showThumbs
        emulateTouch={false}
        showIndicators={false}
        centerSlidePercentage={33.3}
        interval={FOUR_SECONDS}
        items={carouselItems}
        className="rounded-xl"
      />
    </div>
  </section>
);

export { ConstructionProgressSection };
