const Config = {
  appName: "Incorporadora Margarezi",
  metaData: {
    address: "Av. Guarujá, 533 - Sala 01 - Lancaster V - Foz do Iguaçu - PR",
    phone: "(45) 9 9131-5222",
    openHours: `
      Seg. - Qui.: 08:00 às 12:00 das 14:00 as 18:00h<br />
      Sexta: 08:00 às 12:00 das 14:00 as 17:00
    `,
    mainEmail: "ic.imoveismargarezi@gmail.com",
    whatsAppPhoneNumber: "5545991315222",
    whatsAppPhoneNumberFormatted: "(45) 9 9131-5222",
    facebookUrl: "https://www.facebook.com/ImoveisMargarezi",
    instagramUrl: "https://www.instagram.com/imoveismargarezi/",
  },
  paths: {
    HOME: "/",
    SOBRE: "/sobre",
  },
  environments: {
    production: "production",
  },
};

export { Config };
