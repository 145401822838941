import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import cn from "classnames";

export type CarouselItemProps = {
  imageUrl: string;
  alt?: string;
};

type CarouselProps = {
  items: CarouselItemProps[];
  className?: string;
  [rest: string]: any;
};

const Carousel: React.FC<CarouselProps> = ({ items, className, ...rest }) => (
  <ResponsiveCarousel
    emulateTouch
    infiniteLoop
    showThumbs={false}
    showStatus={false}
    dynamicHeight={false}
    className={cn("overflow-hidden", className)}
    {...rest}
  >
    {items.map((item) => (
      <div key={item.imageUrl}>
        <img src={item.imageUrl} alt={item.alt} />
      </div>
    ))}
  </ResponsiveCarousel>
);

export { Carousel };
