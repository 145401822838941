import { WhatsAppButton } from "@/components/WhatsAppButton";
import { Config } from "@/config";

type BookVisitBannerProps = {};

const BookVisitBanner: React.FC<BookVisitBannerProps> = () => (
  <section className="w-screen bg-cover bg-center bg-no-repeat bg-[url('/src/assets/images/condominio-3.jpeg')] relative">
    <div className="bg-yellow-5 opacity-60 absolute w-full h-full" />
    <div className="flex flex-col items-center justify-center max-w-7xl py-6 md:py-0 mx-auto md:h-56 px-4 z-10 relative">
      <span className="text-white uppercase font-bold text-3xl md:text-4xl tracking-widest">
        Agende sua visita!
      </span>
      <div className="mt-4">
        <WhatsAppButton />
      </div>
      <span className="mt-2 text-white font-light">ou ligue para {Config.metaData.phone}</span>
    </div>
  </section>
);

export { BookVisitBanner };
