import cn from "classnames";
import { Icon } from "./Icon";

type ButtonProps = {
  type: "primary" | "dark";
  iconName?: string;
  iconSize?: number;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  type,
  iconName,
  iconSize,
  onClick,
  children,
  className,
}) => {
  const bgColor = {
    primary: "bg-yellow-4",
    dark: "bg-orange-900",
  }[type];
  const fontColor = {
    primary: "text-white",
    dark: "text-white",
  }[type];

  return (
    <button
      className={cn(
        bgColor,
        fontColor,
        "py-2 px-4 rounded-lg transition hover:scale-105",
        className
      )}
      onClick={onClick}
    >
      <div className={cn({ "flex items-center": iconName })}>
        {iconName && <Icon name={iconName} size={iconSize ?? 20} color={fontColor} />}
        <div className={cn({ "ml-2": iconName })}>{children}</div>
      </div>
    </button>
  );
};

export { Button };
