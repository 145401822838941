import React from "react";

type IconProps = {
  name: string;
  color?: string;
  size?: number;
};

const Icon: React.FC<IconProps> = ({ name, color, size }) => {
  const iconPack = name.substring(0, 2).toLowerCase();

  if (iconPack === "md") {
    return React.createElement(require("react-icons/md")[name], {
      color,
      size,
    });
  }

  if (iconPack === "bs") {
    return React.createElement(require("react-icons/bs")[name], {
      color,
      size,
    });
  }

  return null;
};

export { Icon };
