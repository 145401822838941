import cn from "classnames";
import { Button } from "@/components/Button";
import { HeaderMenuItem, HeaderMenuItemProps } from "./HeaderMenuItem";
import { Config } from "@/config";

const MENU_ITEMS: HeaderMenuItemProps[] = [
  {
    label: "Villa Margarezi",
    path: Config.paths.HOME,
  },
  {
    label: "A Incorporadora",
    path: Config.paths.SOBRE,
  },
];

type HeaderMenuItemsProps = {
  isMobileMenuOpen: boolean;
};

const HeaderMenu: React.FC<HeaderMenuItemsProps> = ({ isMobileMenuOpen }) => {
  const handleClick = () => {
    const phoneNumber = Config.metaData.whatsAppPhoneNumber;
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(url, "_blank");
  };

  return (
    <div className={cn("justify-evenly items-center md:flex", { hidden: !isMobileMenuOpen })}>
      <div
        className={cn("m-0 p-0 flex items-center", {
          "flex items-center flex-col justify-evenly w-screen h-screen fixed bg-white left-0 top-14":
            isMobileMenuOpen,
        })}
      >
        <ul
          className={cn("m-0 p-0 flex items-center", {
            "justify-evenly": !isMobileMenuOpen,
            "w-full flex-col justify-start pt-16": isMobileMenuOpen,
          })}
        >
          {MENU_ITEMS.map(({ path, label }) => (
            <HeaderMenuItem
              key={path}
              path={path}
              label={label}
              isMobileMenuOpen={isMobileMenuOpen}
            />
          ))}
        </ul>
        <Button type="primary" className="text-sm" onClick={handleClick}>
          AGENDAR VISITA
        </Button>
      </div>
    </div>
  );
};
export { HeaderMenu };
