import { Map, MapCoordinates } from "@/components/Map";
import { SectionTitle } from "@/components/SectionTitle";

const companyCoordinates: MapCoordinates = {
  lat: -25.4938116,
  lng: -54.5392918,
};

const CompanyLocationSection = () => (
  <section className="w-screen">
    <div className="max-w-7xl px-4 mx-auto">
      <SectionTitle title="Localização" />
    </div>
    <Map
      className="mt-8"
      width="100%"
      height="540px"
      center={companyCoordinates}
      zoom={15}
      pins={[{ title: "Incorporadora Margarezi", ...companyCoordinates }]}
    />
  </section>
);

export { CompanyLocationSection };
