// import { Icon } from "@/components/Icon";
// import { yellow } from "tailwindcss/colors";
import { BenefitCard, BenefitCardProps } from "./BenefitCard";
import ComfortImage from "@/assets/icons/benefits-comfort.png";
import PaymentImage from "@/assets/icons/benefits-payment.png";
import FamilyImage from "@/assets/icons/benefits-family.png";
import QualityImage from "@/assets/icons/benefits-quality.png";

const benefits: BenefitCardProps[] = [
  {
    imgSource: ComfortImage,
    title: "Conforto",
    text: "Condomínio com estrutura completa a 15 minutos do centro de Foz do Iguaçu",
  },
  {
    imgSource: PaymentImage,
    title: "Pagamento",
    text: "Várias opções de financiamento para você realizar seu sonho da casa própria",
  },
  {
    imgSource: FamilyImage,
    title: "Empresa familiar",
    text: "Na Margarezi, você conversa diretamente com a gente, sem intermediação de terceiros",
  },
  {
    imgSource: QualityImage,
    title: "Qualidade",
    text: "Adquira seu imóvel com projeto moderno e com materiais da melhor qualidade",
  },
];

const BenefitsCardsSection = () => (
  <section className="max-w-7xl mx-auto px-4">
    <div className="flex flex-col lg:flex-row justify-center items-center gap-4">
      {benefits.map((benefit) => (
        <BenefitCard
          key={`benefit-${benefit.title}`}
          imgSource={benefit.imgSource}
          title={benefit.title}
          text={benefit.text}
        />
      ))}
    </div>
  </section>
);

export { BenefitsCardsSection };
