import { Map, MapCoordinates } from "@/components/Map";
import { SectionTitle } from "@/components/SectionTitle";

const condominiumCoordinates: MapCoordinates = {
  lat: -25.493719725534714,
  lng: -54.5388142713973,
};

const LocationSection = () => (
  <section className="w-screen">
    <div className="max-w-7xl px-4 mx-auto">
      <SectionTitle title="Região" />
    </div>
    <Map
      className="mt-8"
      width="100%"
      height="540px"
      center={condominiumCoordinates}
      zoom={15}
      pins={[{ title: "Villa Margarezi", ...condominiumCoordinates }]}
    />
  </section>
);

export { LocationSection };
