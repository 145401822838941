import { PageLayout } from "@/components/layout/PageLayout";
import { CondominiumSection } from "@/components/pages/home/CondominiumSection";
import { TeamSection } from "@/components/pages/shared/TeamSection";
import { HomeHero } from "@/components/pages/home/HomeHero";
import { RealEstateDetailsSection } from "@/components/pages/home/RealEstateDetailsSection";
import { RealEstatesSection } from "@/components/pages/home/RealEstatesSection";
import { LocationSection } from "@/components/pages/home/LocationSection";
import { ServicesSection } from "@/components/pages/home/ServicesSection";
import { BookVisitBanner } from "@/components/pages/shared/BookVisitBanner";
import { ConstructionProgressSection } from "@/components/pages/home/ConstructionProgressSection";
import { BenefitsCardsSection } from "@/components/pages/home/BenefitsCardsSection";

const HomePage = () => (
  <PageLayout>
    <HomeHero />
    <div className="-top-10 relative">
      <BenefitsCardsSection />
    </div>
    <div className="mt-10 md:mt-20">
      <CondominiumSection />
    </div>
    <div className="mt-24 md:mt-28">
      <RealEstatesSection />
    </div>
    <div className="mt-24 md:mt-28">
      <BookVisitBanner />
    </div>
    <div className="mt-24 md:mt-28">
      <RealEstateDetailsSection />
    </div>
    <div className="mt-24 md:mt-28">
      <ServicesSection />
    </div>
    <div className="mt-24 md:mt-28">
      <ConstructionProgressSection />
    </div>
    <div className="mt-24 md:mt-28">
      <LocationSection />
    </div>
    <div className="my-24 md:mt-28">
      <TeamSection />
    </div>
  </PageLayout>
);

export { HomePage };
