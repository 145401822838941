import { ListImageSection } from "@/components/pages/shared/ListImageSection";
import Condominum1Image from "@/assets/images/condominio-1.jpeg";
import Condominum2Image from "@/assets/images/condominio-2.jpeg";
import Condominum6Image from "@/assets/images/condominio-6.jpeg";
import Condominum7Image from "@/assets/images/condominio-7.jpeg";
import Condominum9Image from "@/assets/images/condominio-9.jpeg";
import Condominum10Image from "@/assets/images/condominio-10.jpeg";

const ServicesSection = () => (
  <section className="max-w-7xl px-4 mx-auto">
    <ListImageSection
      title="Serviços do condomínio"
      listItems={[
        {
          title: "Portaria automatizada",
          description: `
            <p>Construímos uma estrutura versátil que opcionalmente pode acomodar uma portaria 24 horas.</p>
          `,
          carouselItems: [{ imageUrl: Condominum1Image }, { imageUrl: Condominum2Image }],
        },
        {
          title: "Piscina",
          description: `
            <p>Para maior segurança e comodidade o condomínio dispõe de piscina.</p>
          `,
          carouselItems: [],
        },
        {
          title: "Playground",
          description: `
            <p>Conjunto completo de brinquedos para crianças das mais diversas idades e gostos se divertirem!</p>
          `,
          carouselItems: [
            { imageUrl: Condominum6Image },
            { imageUrl: Condominum7Image },
            { imageUrl: Condominum9Image },
          ],
        },
        {
          title: "Quiosques gourmet",
          description: `
            <p>Equipado com churrasqueira, bancada, pia, wc adaptado e chuveiro. Para você condômino convidar seus amigos e familiares quando quiser!</p>
          `,
          carouselItems: [],
        },
        {
          title: "Praça",
          description: `
            <p>Lugar ideal para socializar, acender uma fogueira ou apenas tomar um sol!</p>
          `,
          carouselItems: [{ imageUrl: Condominum10Image }],
        },
      ]}
    />
  </section>
);

export { ServicesSection };
